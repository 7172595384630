import React, { useContext } from "react";
import clsx from "clsx";
import ReactLink from "@micado-digital/react-link/ReactLink";
import TourDataContext from "../../../context/TourDataContext";

import styles from "./Logo.styles";

const Logo = ({ lang }) => {
	const css = styles();
	const { setTourData } = useContext(TourDataContext);
	const homeLink = `${lang === `de` ? `/` : `/${lang}/`}`;

	const handleClick = e => {
		setTourData(() => ({
			currentStep: 1
		}));
	};

	return (
		<div className={clsx(css.logo, "mco-view-component-logo")}>
			<ReactLink onClick={handleClick} to={homeLink}>
				<img alt="" height={35} src="/img/kitzalps.svg" width={140} />
			</ReactLink>
		</div>
	);
};

export default Logo;
