import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		alignItems: "center",
		background: theme.palette.common.white,
		display: "flex",
		justifyContent: "center",
		height: 96,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		zIndex: 900
	}
}));

export default styles;
