import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import TourDataContext from "../../../context/TourDataContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";
import localNL from "@localNL";

import styles from "./Navigation.styles";

const Container = loadable(() => import("@Components/views/Container"));

const Navigation = ({ lang }) => {
	const css = styles();
	const { tourData } = useContext(TourDataContext);
	const { currentStep, nights, selectedTour: { title: tourTitle } = {} } = tourData;
	const [title, setTitle] = useState("");

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			nl: localNL
		}
	});

	useEffect(() => {
		if (currentStep === 1) {
			setTitle(l("kat.navigation.title"));
			return;
		}

		let title = tourTitle;

		if (nights === 1) {
			title = `${title}, ${nights} ${l("kat.night")}`;
		} else if (nights > 1) {
			title = `${title}, ${nights} ${l("kat.nights")}`;
		}

		setTitle(title);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, nights, l("kat.navigation.title")]);

	return (
		<div className={clsx(css.navigation, "mco-view-component-navigation")}>
			<Container className={clsx(css.container, "mco-view-component-navigation__container")}>
				<div className={clsx(css.title, "mco-view-component-navigation__title")}>{title}</div>
				<div className={clsx(css.listWrapper, "mco-view-component-navigation__list-wrapper")}>
					<ul className={clsx(css.list, "mco-view-component-navigation__list")}>
						<li
							className={clsx(css.item, "mco-view-component-navigation__item", {
								[css.itemSelected]: currentStep === 1,
								"mco-view-component-navigation__item--current-step": currentStep === 1
							})}
						>
							<span className={clsx(css.count, "mco-view-component-navigation__count")}>
								1
							</span>
							<Local identifier="kat.navigation.step1" l={l} />
						</li>
						<li
							className={clsx(css.item, "mco-view-component-navigation__item", {
								[css.itemSelected]: currentStep === 2,
								"mco-view-component-navigation__item--current-step": currentStep === 2
							})}
						>
							<span className={clsx(css.count, "mco-view-component-navigation__count")}>
								2
							</span>
							<Local identifier="kat.navigation.step2" l={l} />
						</li>
						<li
							className={clsx(css.item, "mco-view-component-navigation__item", {
								[css.itemSelected]: currentStep === 3,
								"mco-view-component-navigation__item--current-step": currentStep === 3
							})}
						>
							<span className={clsx(css.count, "mco-view-component-navigation__count")}>
								3
							</span>
							<Local identifier="kat.navigation.step3" l={l} />
						</li>
						<li
							className={clsx(css.item, "mco-view-component-navigation__item", {
								[css.itemSelected]: currentStep === 4,
								"mco-view-component-navigation__item--current-step": currentStep === 4
							})}
						>
							<span className={clsx(css.count, "mco-view-component-navigation__count")}>
								4
							</span>
							<Local identifier="kat.navigation.step4" l={l} />
						</li>
						<li
							className={clsx(css.item, "mco-view-component-navigation__item", {
								[css.itemSelected]: currentStep === 5,
								"mco-view-component-navigation__item--current-step": currentStep === 5
							})}
						>
							<span className={clsx(css.count, "mco-view-component-navigation__count")}>
								5
							</span>
							<Local identifier="kat.navigation.step5" l={l} />
						</li>
					</ul>
				</div>
			</Container>
		</div>
	);
};

export default Navigation;
