import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles/";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/project";
import CSSProperties from "./utils/CSSProperties";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Ui from "@micado-digital/react-ui/components/Ui";
import TourDataContext from "./context/TourDataContext";
import Views from "./views";
import "./css/index.css";

const theme = createTheme(customTheme);

const App = () => {
	const [tourData, setTourData] = useState({
		currentStep: 1
	});

	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<TourDataContext.Provider value={{ tourData, setTourData }}>
								<Routes>
									<Route path="/*" element={<Views />} />
								</Routes>
							</TourDataContext.Provider>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
