import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	langSelection: {
		cursor: "pointer",
		[theme.breakpoints.up("sm")]: {
			position: "absolute",
			right: theme.spacing(3)
		},
		[theme.breakpoints.up(1350)]: {
			margin: "0 -652px 0 0",
			right: "50%"
		},
		"& svg": {
			display: "block",
			height: "auto",
			width: theme.spacing(3)
		}
	}
}));

export default styles;
