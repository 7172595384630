import React, { Suspense, useContext, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";

import styles from "./LangSelection.styles";

const UiReactLangSelection = loadable(() =>
	import("@micado-digital/react-lang-selection/ReactLangSelection")
);

const LangSelection = ({ lang }) => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { meta: { links } = {} } = pageData;
	const [profileData] = useContext(WebProfileContext);
	const { languages } = profileData;
	const [showLang, setShowLang] = useState(false);

	return (
		<>
			<div
				className={clsx(css.langSelection, "mco-view-component-lang-selection")}
				onClick={setShowLang}
			>
				{lang === "de" && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						width="48px"
						height="48px"
					>
						<path fill="#FFC107" d="M2 29H46V39H2z" />
						<path fill="#FF3D00" d="M2 19H46V29H2z" />
						<path fill="#455A64" d="M2 9H46V19H2z" />
					</svg>
				)}
				{lang === "en" && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						width="48px"
						height="48px"
					>
						<path fill="#3F51B5" d="M2 10H46V38H2z" />
						<path fill="#FFF" d="M2 14.216L22.81 26.935 25.939 21.815 6.608 10 2 10z" />
						<path fill="#FFF" d="M46 10L42.391 10 23.061 21.814 26.189 26.935 46 14.826z" />
						<path fill="#FFF" d="M26.189 20.271L23.061 25.391 43.691 38 46 38 46 32.379z" />
						<path fill="#FFF" d="M2 32.991L2 38 5.31 38 25.939 25.392 22.811 20.271z" />
						<path fill="#FFF" d="M2 20H46V28H2z" />
						<path fill="#FFF" d="M20 10H28V38H20z" />
						<g>
							<path
								fill="#E53935"
								d="M17.218 20L2 10.699 2 13.043 13.382 20zM44.309 10L27.947 20 31.782 20 46 11.311 46 10zM33.082 28L46 35.895 46 33.551 36.917 28zM15.918 28L2 36.506 2 38 3.392 38 19.753 28zM2 22H46V26H2z"
							/>
							<path fill="#E53935" d="M22 10H26V38H22z" />
						</g>
					</svg>
				)}
				{lang === "nl" && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						width="48px"
						height="48px"
					>
						<path fill="#3F51B5" d="M2 29H46V39H2z" />
						<path fill="#ECEFF1" d="M2 19H46V29H2z" />
						<path fill="#FF3D00" d="M2 9H46V19H2z" />
					</svg>
				)}
			</div>
			<Suspense fallback={<></>}>
				{showLang && (
					<UiReactLangSelection
						languages={languages}
						currentLanguage={lang}
						links={links}
						setOverlay={setShowLang}
					/>
				)}
			</Suspense>
		</>
	);
};

export default LangSelection;
