import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		overflow: "hidden",
		padding: "96px 0 0 0",
		[theme.breakpoints.up("md")]: {
			paddingTop: 145
		}
	},
	pageHideElements: {
		"& .mco-elements > *:not(.mco-view-component-container-tm-kat-booking)": {
			display: "none"
		}
	},
	pageWithoutNavigation: {
		[theme.breakpoints.up("md")]: {
			paddingTop: 96
		}
	}
}));

export default styles;
