import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	navigation: {
		background: "#21445b",
		borderBottom: "1px solid #4d6473",
		color: "#FFFFFF",
		padding: "10px 0",
		width: "100%",
		zIndex: 200,
		[theme.breakpoints.up("md")]: {
			position: "fixed",
			top: 96
		}
	},
	container: {
		display: "grid",
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(2),
			gridTemplateColumns: "1fr auto"
		}
	},
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			marginBottom: 0
		}
	},
	list: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gap: theme.spacing(0.5),
		[theme.breakpoints.up("sm")]: {
			gridTemplateColumns: "1fr 1fr 1fr"
		},
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(3),
			gridTemplateColumns: "repeat(5, auto)"
		}
	},
	item: {
		alignItems: "center",
		color: "#4d6473",
		display: "flex",
		fontSize: 18,
		fontWeight: 400,
		gap: theme.spacing(1)
	},
	itemSelected: {
		color: "#FFFFFF",
		"& .mco-view-component-navigation__count": {
			background: "#FFFFFF"
		}
	},
	count: {
		alignItems: "center",
		background: "#4d6473",
		borderRadius: "50%",
		color: "#21445b",
		display: "flex",
		fontSize: 14,
		justifyContent: "center",
		height: 24,
		width: 24
	}
}));

export default styles;
