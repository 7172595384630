import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	footer: {
		background: "#17394f",
		marginTop: "auto",
		padding: theme.spacing(4, 0, 2, 0),
		[theme.breakpoints.up(700)]: {
			padding: theme.spacing(4, 0)
		}
	},
	container: {
		[theme.breakpoints.up(700)]: {
			alignItems: "center",
			display: "flex",
			justifyContent: "space-between"
		}
	},
	logo: {
		display: "flex",
		justifyContent: "center",
		"& svg": {
			display: "block"
		}
	},
	menu: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
			gap: theme.spacing(2)
		}
	},
	item: {
		display: "flex"
	},
	link: {
		color: theme.palette.common.white,
		display: "block",
		fontSize: 18,
		padding: theme.spacing(1),
		"&:hover": {
			textDecoration: "underline"
		}
	}
}));

export default styles;
