export const theme = {
	typography: {
		fontWeightLight: 400,
		h1: {
			fontFamily: "Creighton-Pro",
			fontSize: "clamp(2.5rem, 8vw, 64px)",
			fontWeight: 500,
			letterSpacing: 3,
			lineHeight: 1,
			textTransform: "uppercase"
		},
		h2: {
			color: "#E01B1B !important",
			fontFamily: "Creighton-Pro",
			fontSize: "clamp(1.8rem, 8vw, 38px)",
			fontWeight: 500,
			letterSpacing: 3,
			lineHeight: 1,
			textTransform: "uppercase"
		},
		h3: {
			fontSize: "clamp(1.5rem, 5vw, 30px)",
			fontWeight: 500,
			lineHeight: 1.2
		},
		h4: {
			fontSize: "clamp(1.5rem, 5vw, 26px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h5: {
			color: "#E01B1B !important",
			fontSize: "clamp(1.5rem, 5vw, 22px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		h6: {
			fontSize: "clamp(1.5rem, 5vw, 20px)",
			fontWeight: 700,
			lineHeight: 1.2
		},
		caption: {
			lineHeight: 1.4
		},
		button: {
			fontSize: 18,
			textTransform: "none"
		}
	},
	palette: {
		header: {
			background: "#FFFFFF"
		},
		footer: {
			background: "#E01B1B"
		},
		primary: {
			light: "#E29C9C",
			main: "#E01B1B",
			dark: "#892626",
			contrastText: "#FFFFFF"
		},
		secondary: {
			light: "#4C768F",
			main: "#21445B",
			dark: "#0C2638"
		},
		error: {
			light: "#F09077",
			main: "#CB2821",
			dark: "#921123"
		},
		warning: {
			light: "#FFDE77",
			main: "#D9CB0B",
			dark: "#9A8E2E"
		},
		info: {
			light: "#71B7EF",
			main: "#2196F3",
			dark: "#146FB8"
		},
		success: {
			light: "#B9E471",
			main: "#66A61A",
			dark: "#3C770D",
			contrastText: "#FFFFFF"
		},
		grey: {
			50: "#F1F1F1",
			100: "#F5F4F4",
			200: "#F0F0EF",
			300: "#D8D7D4",
			400: "#B4B1AC",
			500: "#88837C",
			600: "#706C66",
			700: "#4E4A46",
			800: "#33302E",
			900: "#1B1918",
			A100: "rgba(0, 0, 0, 0,1)",
			A200: "rgba(0, 0, 0, 0,3)",
			A400: "rgba(0, 0, 0, 0,5)",
			A700: "rgba(0, 0, 0, 0,8)"
		},
		text: {
			primary: "#4D4945",
			secondary: "#86827D"
		},
		background: {
			backdrop: "rgba(0, 0, 0, 0.65)",
			body: "#CCCCCC",
			default: "#FFFFFF",
			header: "#FFFFFF",
			headerScroll: "#FFFFFF",
			submenuDesktop: "#21445BDD",
			mobileContainer: "#21445b",
			footer02: {
				footer: "#FFFFFF"
			},
			swipePopUp: "rgba(36, 69, 91, 0.9)",
			pwaAlert: "#21445b",
			teaser: "#FFFFFF"
		},
		foreground: {
			swipePopUp: "#FFFFFF"
		}
	},
	shape: {
		borderRadius: 8
	},
	props: {
		MuiButton: {
			disableRipple: true
		}
	},
	overrides: {
		MuiButton: {
			root: {
				color: "#E01B1B !important",
				boxShadow: "none !important",
				fontSize: 18,
				letterSpacing: 0.4,
				lineHeight: "26px",
				textTransform: "none",
				height: 48,
				padding: "0 20px"
			},
			contained: {
				background: "#E01B1B !important",
				color: "#ffffff !important"
			},
			outlined: {
				borderColor: "#E01B1B !important"
			},
			sizeSmall: {
				fontSize: 18,
				letterSpacing: 0.4,
				lineHeight: "21px",
				height: 40,
				padding: "0 16px"
			},
			sizeLarge: {
				height: 60,
				fontSize: 18
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	customTypography: {
		menu: {
			mobile: {
				menu1: {
					fontSize: 32,
					fontWeight: 500,
					lineHeight: 1
				},
				menu2: {
					fontWeight: 400,
					fontSize: 24,
					lineHeight: 1.2
				}
			},
			desktop: {
				menu1: {
					fontSize: 22,
					fontWeight: 500,
					lineHeight: "26px"
				},
				menu2: {
					fontSize: 20,
					fontWeight: 500,
					lineHeight: "28px"
				}
			}
		},
		textLogo: {
			title: {
				fontWeight: 700,
				fontSize: 28,
				lineHeight: 1
			},
			addition: {
				fontWeight: 400,
				fontSize: 12,
				fontStyle: "normal",
				letterSpacing: 2,
				lineHeight: 1.5,
				textTransform: "uppercase"
			}
		},
		footer02: {
			label: {
				fontSize: 24,
				fontWeight: 400,
				lineHeight: 1
			},
			footermenu: {
				mobile: {
					level1: {
						fontWeight: 400,
						fontSize: 16,
						lineHeight: "24px"
					},
					level2: {
						fontWeight: 400,
						fontSize: 14,
						lineHeight: "24px"
					}
				},
				desktop: {
					level2: {
						fontWeight: 400,
						fontSize: 17,
						lineHeight: "24px"
					}
				}
			},
			metamenu: {
				mobile: {
					level1: {
						fontSize: 14,
						fontWeight: 400,
						letterSpacing: 1,
						lineHeight: "20px"
					}
				}
			}
		},
		form: {
			field: {
				fontWeight: 400,
				fontSize: 16,
				letterSpacing: 0.4,
				lineHeight: 1.25
			},
			label: {
				fontWeight: 700,
				fontSize: 14,
				letterSpacing: 0.4,
				lineHeight: 1.286
			}
		},
		datepicker: {
			weekdays: {
				fontWeight: 700,
				fontSize: 12,
				lineHeight: 1.5
			}
		}
	}
};
