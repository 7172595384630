import React from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";

import styles from "./Footer.styles";

const Container = loadable(() => import("@Components/views/Container"));
const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));

const Footer = ({ lang }) => {
	const css = styles();
	const items = useFindMenuGroup("Metamenü");
	const homeLink = `${lang === `de` ? `/` : `/${lang}/`}`;

	return (
		<div className={clsx(css.footer, "mco-view-component-footer")}>
			<Container className={clsx(css.container, "mco-view-component-footer__container")}>
				<div className={clsx(css.logo, "mco-view-component-footer__logo")}>
					<ReactLink to={homeLink}>
						<svg
							width="185"
							height="47"
							viewBox="0 0 185 47"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M148.66 27.3097L148.717 31.8287C149.289 27.6117 151.388 26.8057 153.831 26.8057C156.136 26.8057 158.412 27.6707 159.057 30.2737C159.306 31.2687 159.336 32.3047 159.314 33.3437L159.272 42.3137H152.302L152.346 37.7797C152.353 36.8907 152.371 35.3787 152.226 34.8077C151.989 33.8397 151.294 33.5467 150.553 33.5467C149.822 33.5467 149.023 34.2287 148.721 34.5067L148.797 42.3137H141.961C141.961 42.3137 141.928 27.3097 141.934 27.3097H148.66Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M108.828 34.5616L108.82 36.5456C109.158 37.6956 109.932 38.1286 111.099 38.1286C112.857 38.1286 113.719 36.5216 113.125 34.1416C112.901 33.2486 112.067 32.6536 110.844 32.6536C109.546 32.6536 109.126 33.5616 108.828 34.5616ZM108.919 27.3446L108.832 31.8876C109.928 27.1506 111.482 26.8096 114.137 26.8096C117.116 26.8096 119.004 28.1466 119.766 31.1936C119.969 32.0106 120.113 33.4226 120.131 35.4346C120.149 39.3936 119.203 42.8376 114.581 42.8376C113.057 42.8376 109.705 42.5396 108.816 38.9336L108.849 46.0626H102.11L102.145 27.3446H108.919Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M86.3035 3.368L87.1505 3.376C88.4805 3.368 89.1685 3.348 89.2825 2.777C89.3575 2.408 89.3995 1.738 89.3825 1.166C89.3235 0.174 89.2645 0 87.3825 0H84.9435C84.2995 0 83.9335 0.174 83.8605 0.524C83.8205 0.722 83.8085 1.912 83.8265 2.63C83.8495 3.348 84.7145 3.376 86.3035 3.368Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M94.7887 3.376L95.6407 3.368C97.2277 3.376 98.0887 3.348 98.1087 2.63C98.1357 1.912 98.1187 0.718 98.0807 0.519C98.0087 0.174 97.6407 0 96.9977 0H94.5607C92.6757 0 92.6197 0.174 92.5557 1.166C92.5437 1.737 92.5857 2.404 92.6587 2.777C92.7687 3.348 93.4587 3.368 94.7887 3.376Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M177.637 9.25167V4.64167H170.837L170.856 19.6327L177.637 19.6367V14.4717C177.94 13.1077 178.915 11.4217 181.39 11.4217H184.253V4.64167C178.839 3.83667 178.018 6.29967 177.637 9.25167Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M142.066 1.12695V19.637H148.806L148.802 1.12695H142.066Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M79.6214 18.105C79.8444 17.081 80.2154 13.948 80.2094 12.746C80.2084 12.635 80.2084 12.528 80.2064 12.425C80.1774 10.584 80.0394 9.28298 79.8444 8.50898C79.0844 5.46198 77.1574 4.12598 74.1784 4.12598C73.0314 4.12598 69.5654 3.88698 69.1114 8.81898C69.1004 8.90198 69.0944 8.98898 69.0864 9.07598V8.81098L69.1174 1.12598H62.4014L62.4094 7.64798L62.4214 19.676L69.0524 19.636L69.0654 15.808C69.0654 16.843 70.5344 18.879 71.3314 19.628C71.3314 19.628 76.0114 17.454 79.6214 18.105ZM71.3534 15.447C70.1794 15.447 69.4114 15.011 69.0714 13.865L69.0784 11.877C69.3744 10.886 69.7884 9.98498 71.0584 9.96898H71.0624H71.0944C71.6874 9.96898 72.1584 10.108 72.5124 10.358L72.5144 10.362C72.8844 10.616 73.1294 11.001 73.2404 11.458C73.8354 13.837 73.1074 15.447 71.3534 15.447Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M130.685 4.08984C127.69 4.08984 124.262 4.18884 122.865 6.81484C121.989 8.47784 121.408 12.1398 122.218 15.4158C123.32 19.7988 127.344 20.1168 130.505 20.1168C133.09 20.1168 138.685 20.3468 139.286 15.0148H133.762C133.762 15.0148 133.541 15.7128 132.295 16.0778C130.962 16.4748 129.058 16.1968 128.377 14.6138C128.285 14.4038 128.071 13.7228 128.082 13.3968H139.391C139.553 12.4128 139.566 10.4018 139.46 9.49284C138.989 5.35984 136.239 4.08984 130.685 4.08984ZM128.221 10.2508C128.396 8.16784 129.383 7.67584 131.002 7.67584C131.597 7.67584 132.819 7.94984 133.097 9.06484C133.192 9.43384 133.211 9.80684 133.255 10.2508H128.221Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M130.685 26.8057C127.69 26.8057 124.262 26.9007 122.865 29.5317C121.989 31.1937 121.408 34.8557 122.218 38.1287C123.32 42.5157 127.344 42.8337 130.505 42.8337C133.09 42.8337 138.685 43.0637 139.286 37.7357H133.762C133.762 37.7357 133.541 38.4257 132.295 38.7987C130.962 39.1917 129.058 38.9177 128.377 37.3307C128.285 37.1167 128.071 36.4427 128.082 36.1167H139.391C139.553 35.1287 139.566 33.1177 139.46 32.2127C138.989 28.0757 136.239 26.8057 130.685 26.8057ZM128.221 32.9707C128.396 30.8837 129.383 30.3957 131.002 30.3957C131.597 30.3917 132.819 30.6617 133.097 31.7847C133.192 32.1537 133.211 32.5267 133.255 32.9707H128.221Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M160.068 4.08984C157.069 4.08984 153.639 4.18884 152.244 6.81484C151.367 8.47784 150.786 12.1398 151.604 15.4158C152.7 19.7988 156.722 20.1168 159.884 20.1168C162.474 20.1168 168.06 20.3508 168.668 15.0148H163.139C163.139 15.0148 162.922 15.7128 161.672 16.0778C160.344 16.4748 158.436 16.1968 157.757 14.6138C157.663 14.4038 157.451 13.7228 157.466 13.3968H168.764C168.933 12.4128 168.941 10.4018 168.835 9.49284C168.362 5.35984 165.621 4.08984 160.068 4.08984ZM157.603 10.2508C157.777 8.16784 158.763 7.67584 160.375 7.67584C160.975 7.67584 162.198 7.94984 162.474 9.06484C162.566 9.43384 162.595 9.80684 162.635 10.2508H157.603Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M114.164 4.12595C111.865 4.12595 109.578 5.06195 109.011 9.27595V1.12695H102.132V19.633L107.07 19.637H109.011V11.898C109.011 11.616 109.538 10.648 110.768 10.648C111.502 10.648 112.558 11.112 112.558 12.108C112.558 12.763 112.554 14.211 112.541 15.103V19.633H119.492V10.716C119.513 9.67595 119.524 8.58495 119.274 7.59295C118.623 4.98695 116.474 4.12595 114.164 4.12595Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M88.6729 33.2764C88.6729 33.2764 86.8399 36.4344 83.2459 37.5214L82.2529 42.3294H90.1209C89.7829 39.7704 89.1349 35.1294 88.6729 33.2764Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M92.5482 4.6416V13.4296C92.5352 14.3006 92.1012 15.3086 91.2872 15.3996C91.2032 15.4076 91.1122 15.4196 91.0202 15.4196C90.5762 15.4196 90.2612 15.3206 89.9372 15.0386H89.9342H89.9372C89.7352 14.8606 89.5822 14.6186 89.5072 14.3086C89.4252 13.9846 89.3712 13.2146 89.3662 12.5446V4.6416H82.5362L82.5472 11.9496C82.5432 12.2786 82.5362 12.6556 82.5362 13.0446V13.0486C82.5342 14.1356 82.5712 15.3956 82.8262 16.4116C83.7372 20.0576 88.9792 20.1486 91.3332 20.1526C95.5282 20.1486 97.8192 19.1766 98.7762 17.4076C99.1372 16.7326 99.3262 15.9476 99.3352 15.0466V4.6416H92.5482Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M92.5703 23.8857V42.4527H99.3263V23.8937L92.5703 23.8857Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M27.3063 0.0078125H24.2523C21.8923 0.0078125 21.8203 0.181812 21.7433 1.17781C21.7213 1.74981 21.7723 2.41981 21.8743 2.79281C22.0083 3.36381 22.8733 3.39181 24.5363 3.39181H25.6043C27.5953 3.39181 28.6763 3.36381 28.7023 2.64581C28.7343 1.92381 28.7133 0.733813 28.6653 0.531812C28.5763 0.181812 28.1183 0.0078125 27.3063 0.0078125Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M25.424 4.70842H25.375C24.056 4.70442 22.715 4.60542 21.875 4.48242L21.884 19.7394H28.644L28.635 4.48242C27.852 4.60542 26.885 4.70842 25.424 4.70842Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.457 1.10273L7.208 9.42973V1.09473H0V19.7397H7.208L7.204 11.7707L12.228 19.7397H20.004L14.538 10.9777L20.437 1.10273H11.457Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M74.8262 30.7929C73.5352 32.2289 70.2012 36.6759 70.2012 36.6759C83.1932 40.0569 90.2312 32.8639 90.7472 27.4209C88.0212 30.1819 84.0832 33.2569 74.8262 30.7929Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M43.6053 15.3637C42.6943 15.5657 41.7043 15.7567 40.7093 15.8957C39.5833 15.8877 38.5953 15.6687 38.3613 14.9747C38.2313 14.5737 38.2873 13.8337 38.2923 13.3807L38.2973 9.28673H43.6053V4.63673H38.3083L38.2973 1.09473H31.5283V14.5627L31.5353 15.7127C31.5283 16.5377 31.5913 17.2477 31.8983 17.8397C32.8593 19.7397 34.8193 20.2947 38.1023 20.1247C40.0143 20.0217 42.2923 19.6247 43.6053 18.1927V15.3637Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M60.8744 8.80377L60.8694 4.66977L47.8444 4.65777C47.3974 4.69777 46.4014 4.50677 46.6874 3.38477C44.4814 5.81277 45.5344 9.23577 47.3894 9.32777L52.6504 9.30777L45.6094 17.9358L46.9324 19.8718C50.9944 21.2048 54.6594 21.2838 60.0694 28.3538C60.0694 28.3538 63.5884 24.9818 65.5464 23.5138C59.9024 19.5618 52.5864 18.3398 52.5864 18.3398L60.8744 8.80377Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M87.7912 29.3254C85.1182 20.8114 79.4912 13.2194 64.5402 26.1594C64.5402 26.1594 52.6672 37.1804 47.4902 42.4614H64.0902C66.6502 38.9224 72.0052 31.8124 73.1032 30.6984C80.0662 22.1764 83.8922 25.1124 85.1692 26.0924C85.9802 26.8144 87.7912 29.3254 87.7912 29.3254Z"
								fill="white"
							/>
						</svg>
					</ReactLink>
				</div>
				<ul className={clsx(css.menu, "mco-view-component-footer__menu")}>
					{items?.map(item => {
						const { id, link, linkRel, target, title } = item;

						return (
							<li className={clsx(css.item, "mco-view-component-footer__item")} key={id}>
								<ReactLink
									className={clsx(css.link, "mco-view-component-footer__link")}
									dangerouslySetInnerHTML={{ __html: title }}
									rel={linkRel}
									target={target}
									to={link}
								/>
							</li>
						);
					})}
				</ul>
			</Container>
		</div>
	);
};

export default Footer;
