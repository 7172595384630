import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import TourDataContext from "../context/TourDataContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import Locals from "@micado-digital/react-ui/components/Locals";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import Default from "./Default";
import Header from "@Components/views/Header";
import Footer from "@Components/views/Footer";
import Navigation from "@Components/views/Navigation";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import ReactTracking from "@micado-digital/react-tracking/ReactTracking";

import styles from "./Views.styles";

const Tracking = () => {
	const [profileData] = useContext(WebProfileContext);
	const { trackingCodes = [] } = profileData;

	return <ReactTracking codes={trackingCodes} />;
};

const Views = () => {
	const [pageData] = useContext(PageContext);
	const { authorID, lang, layoutTag, layoutVariant, meta = {} } = pageData;
	const { tourData } = useContext(TourDataContext);
	const { currentStep = 1 } = tourData;
	const css = styles();
	const currentLocation = useIsCurrentLocation();

	if (!lang) return null;

	const {
		canonical: metaCanonical,
		description: metaDescription,
		indexable: metaIndexable,
		image: metaImage,
		keywords: metaKeywords,
		lat: metaLat,
		links: metaLinks,
		lng: metaLng,
		title: metaTitle
	} = meta;

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<div
						className={clsx(css.page, "mco-page", {
							[css.pageHideElements]: currentStep > 1,
							"mco-page--hide-elements": currentStep > 1,
							[css.pageWithoutNavigation]: layoutVariant === "no-navigation"
						})}
					>
						<Header lang={lang} />
						{layoutVariant !== "no-navigation" && <Navigation lang={lang} />}
						{layoutTag === "default" && currentLocation && <Default />}
						<Footer lang={lang} />
					</div>
					<ReactMetaData01
						canonical={metaCanonical}
						description={metaDescription}
						index={metaIndexable}
						image={metaImage}
						keywords={metaKeywords}
						lat={metaLat}
						lang={lang}
						links={metaLinks}
						lng={metaLng}
						title={metaTitle}
					/>
					<Tracking />
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
