import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logoKAT: {
		marginRight: theme.spacing(3),
		position: "relative",
		[theme.breakpoints.up("sm")]: {
			marginRight: theme.spacing(6)
		},
		"&:after": {
			background: "#f6f6f6",
			content: "''",
			display: "block",
			height: 64,
			position: "absolute",
			right: -theme.spacing(1),
			top: "50%",
			transform: "translate(0, -50%)",
			width: 1,
			[theme.breakpoints.up("sm")]: {
				right: -theme.spacing(2)
			}
		},
		"& img": {
			display: "block",
			height: "auto",
			width: 70,
			[theme.breakpoints.up("sm")]: {
				width: 80
			}
		}
	}
}));

export default styles;
