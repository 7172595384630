import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";

import styles from "./LogoKAT.styles";

const LogoKAT = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { lang = "de", layoutVariant } = pageData;

	return (
		<div className={clsx(css.logoKAT, "mco-view-component-logo-kat")}>
			{layoutVariant === "kat-bike" ? (
				<img alt="" height={79} src={`/img/kat-bike-${lang}.svg`} width={80} />
			) : (
				<img alt="" height={79} src={`/img/kat-walk-${lang}.svg`} width={80} />
			)}
		</div>
	);
};

export default LogoKAT;
