import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		marginRight: theme.spacing(3),
		position: "relative",
		"&:after": {
			background: "#f6f6f6",
			content: "''",
			display: "block",
			height: 64,
			position: "absolute",
			right: -theme.spacing(1.5),
			top: "50%",
			transform: "translate(0, -50%)",
			width: 1,
			[theme.breakpoints.up("sm")]: {
				display: "none"
			}
		},
		"& img": {
			display: "block",
			height: "auto",
			width: 120,
			[theme.breakpoints.up("sm")]: {
				width: 160
			}
		}
	}
}));

export default styles;
