import React from "react";
import clsx from "clsx";
import Logo from "../Logo";
import LogoKAT from "../LogoKAT";
import LangSelection from "../LangSelection";

import styles from "./Header.styles";

const Header = ({ lang }) => {
	const css = styles();

	return (
		<div className={clsx(css.header, "mco-view-component-header")}>
			<LogoKAT />
			<Logo lang={lang} />
			<LangSelection lang={lang} />
		</div>
	);
};

export default Header;
